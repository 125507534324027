"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getAppsEnvironment = exports.getEnvironmentForKeys = exports.ROBOTS_META_TAG = exports.RUM_TAG = exports.RUM_ID = exports.ROOT_APP_CONFIG_SCRIPT_TEMPLATE = exports.ROOT_APP_CONFIG_ID = void 0;
exports.ROOT_APP_CONFIG_ID = 'root-app-config';
exports.ROOT_APP_CONFIG_SCRIPT_TEMPLATE = "<script type=\"application/json\" id=\"".concat(exports.ROOT_APP_CONFIG_ID, "\"/>");
exports.RUM_ID = 'rum';
exports.RUM_TAG = "<script id=\"".concat(exports.RUM_ID, "\"></script>");
exports.ROBOTS_META_TAG = '<meta name="robots" content="noindex, nofollow">';
var getEnvironmentForKeys = function (keys) {
    return Object.keys(keys).reduce(function (stack, key) {
        if (process.env[key]) {
            stack[key] = process.env[key];
        }
        return stack;
    }, keys);
};
exports.getEnvironmentForKeys = getEnvironmentForKeys;
var getAppsEnvironment = function () {
    return (0, exports.getEnvironmentForKeys)({
        APPS_TABLE_NAME: '',
        APPS_BUCKET_NAME: '',
        APPS_TABLE_TYPE_INDEX: '',
    });
};
exports.getAppsEnvironment = getAppsEnvironment;
