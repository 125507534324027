import { Alert, Button } from '@mui/material';
import React from 'react';
import { FormattedMessage, IntlProvider } from 'react-intl';
import de from '../translations/de.json';
export var ErrorNotification = function (_a) {
    var err = _a.err, properties = _a.properties;
    var reloadPage = function () {
        location.reload();
    };
    // Note: React deals differently with errors in development/production mode.
    // In production cloudwatch rum does not collect errors that have been caught by react's error boundaries.
    // This is why we want to send them manually.
    // Important: When testing locally (development mode), 2 entries with the js_error_event are beeing pushed (the automatically collected and the manually sent),
    if (err && properties && window.cwr) {
        window.cwr('recordEvent', {
            type: 'com.amazon.rum.js_error_event',
            data: {
                version: '1.0.0',
                type: 'TypeError',
                message: err.message,
                filename: properties.name,
                lineno: 1,
                colno: 1,
                stack: err.stack,
            },
        });
    }
    return (React.createElement(IntlProvider, { locale: 'de', messages: de },
        React.createElement(Alert, { action: React.createElement(Button, { variant: "contained", size: "small", onClick: function () { return reloadPage(); } },
                React.createElement(FormattedMessage, { id: "error.reloadPage" })), severity: "error", sx: { mx: 0, mt: 2 } },
            React.createElement(FormattedMessage, { id: "error.defaultMessage" }))));
};
